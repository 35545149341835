import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { Top2, Section, Row, Column, Text } from "src/components/styled"

const Limited = styled(Column)`
  .gatsby-image-wrapper {
    max-width: 400px;
    margin: 0 auto;
  }
`

interface DataType {
  textile: { childImageSharp: Media }
  chart: { childImageSharp: Media }
}

const TextilePage: GatsbyPage<DataType> = ({ data }) => {
  return (
    <Layout title="recycledTextile" subMenu="recycled">
      <Top2>
        <FormattedMessage id="recycled.textile" tagName="h1" />
        <FormattedMessage id="recycled.textileText" tagName="p" />
      </Top2>
      <Section>
        <Image fluid={data.textile.childImageSharp.fluid} />
      </Section>
      <Section>
        <Row>
          <Limited>
            <Image fluid={data.chart.childImageSharp.fluid} />
          </Limited>
          <Column>
            <FormattedMessage id="recycled.text" tagName={Text} />
            <br />
            <br />
            <FormattedMessage id="recycled.stat" tagName="h1" />
            <FormattedMessage id="recycled.source" tagName={Text} />
          </Column>
        </Row>
      </Section>
    </Layout>
  )
}

export default TextilePage

export const query = graphql`
  query {
    textile: file(relativePath: { eq: "recycled/textile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chart: file(relativePath: { eq: "recycled/recycling.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
